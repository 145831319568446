import { BaseUrl } from '../ApiUrl'
import AXIOS_SERVICE from '../../utils/AxiosUtil'

const OnhandStockService = () => {}

OnhandStockService.GetOnhandStock = async()=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/stock/onhand`, {})
}

OnhandStockService.GetOnhandStockByEngineerId = async(id)=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/stock/onhand/engineer/${id}`, {})
}

OnhandStockService.GetOnhandStockById = async(id)=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/stock/onhand/${id}`, {})
}

OnhandStockService.InsertOnhandStock = async(payload)=> {  
  return await AXIOS_SERVICE('post', `${BaseUrl}/stock/onhand`, payload)
}

OnhandStockService.UpdateOnhandStock = async(payload, id)=> {  
  return await AXIOS_SERVICE('put', `${BaseUrl}/stock/onhand/${id}`, payload)
}

OnhandStockService.UpdateOnhandStockDetail = async(payload, id)=> {  
  return await AXIOS_SERVICE('put', `${BaseUrl}/stock/onhand/editDetail/${id}`, payload)
}

OnhandStockService.DeleteOnhandStock = async(id)=> {  
  return await AXIOS_SERVICE('delete', `${BaseUrl}/stock/onhand/${id}`, {})
}



 export default OnhandStockService