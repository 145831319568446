<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-row>
          <b-col md="4" class="my-1">
            <h2><b>Stock Onhand</b></h2>
          </b-col>
          <b-col md="8" class="my-1">
            <b-form inline class="d-flex justify-content-end">
              <b-input-group class="input-group-merge" style="width: 40%">
                <b-form-input
                  id="basic-password1"
                  type="text"
                  placeholder="Search..."
                  v-model="filter"
                />
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon" class="cursor-pointer" />
                </b-input-group-append>
              </b-input-group>
            </b-form>
          </b-col>

          <b-col cols="12">
            <b-table
              style="font-size: 12px"
              class="shadow"
              small
              striped
              bordered
              hover
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="visibleFields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              :busy="isLoading"
            >
            <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Loading...</strong>
                </div>
              </template>
              <template #cell(no)="row">
                <center>{{ row.index + 1 }}</center>
              </template>

              <template #cell(onhandDate)="row">
                <center>{{ convertDate(row.item.onhandDate) }}</center>
              </template>
              <template #cell(action)="row" v-if="onhandPermission=='edit'">
                <center>
                  <b-button
                    variant="outline-secondary"
                    class="mr-1"
                    size="sm"
                    pill
                    @click="rollbackStock(row.item)"
                  >
                    Stock
                  </b-button>
                  <b-button
                    variant="primary"
                    size="sm"
                    pill
                    v-b-modal.edit-detail-modal
                    @click="onSelectItemForEdit(row.item)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="cursor-pointer text-white"
                    />
                  </b-button>
                </center>
              </template>
            </b-table>
          </b-col>
          <b-col cols="2">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
          </b-col>

          <b-col cols="10" class="d-flex justify-content-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              variant="secondary"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <EditDetailModal :onhandData="onhandData" @saved="getOnhandList"/>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BForm,
  BSpinner
} from "bootstrap-vue";

import Field from "./field";

//COMPONENTS
import EditDetailModal from "./component/modal/editDetail.vue";

//SERVICES
import OnhandService from "@/services/stock/onhand";
import moment from "moment";

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BForm,
    BSpinner, 
    EditDetailModal,
  },
  data() {
    return {
      perPage: 1000,
      pageOptions: [1000, 2000, 5000],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      fields: Field,
      items: [],
      onhandData:{},
      isLoading:false,
      userLogin:{}
    };
  },
  created(){
    const userLoginData = localStorage.getItem("userData");
    const userData = JSON.parse(userLoginData);
    this.userLogin = userData;
  },
  computed :{
    onhandPermission () {
      const permission = this.$store.getters["user/getPermissionPage"]("STK003")
      if(permission=="disable"){
        localStorage.removeItem("userData")
        window.location.href="/not-authorized"
      }
      return permission       
    },
    visibleFields() {
        return this.fields.filter(field => {
          if(this.$store.getters["user/getPermissionPage"]("STK003") !== "edit"){
            return field.key !== "action"
          }
          return field
        })
    }    
  },
  mounted() {
    this.getOnhandList();
    
  },
  methods: {
    async getOnhandList() {
      this.isLoading = true
      const result = await OnhandService.GetOnhandStock();
      this.items = result.data.data;
      this.totalRows = this.items.length;
      this.isLoading = false
    },

    async rollbackStock(item){
      const isConfirm = confirm("ต้องการคืน Stock หรือไม่?")
      if(!isConfirm) return false;
      this.isLoading = true
      await OnhandService.UpdateOnhandStock({...item, isRollback:true, createBy:this.userLogin.userId},item.id);
      await this.getOnhandList()
      this.isLoading = false
    }, 

    onSelectItemForEdit(item){ 
      this.onhandData = item  
    },
    convertDate(date) {
      return moment(date).subtract(7,'h').format("yyyy-MM-DD HH:mm:ss");
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style scoped>
.table-hover tbody tr:hover {
  background-color: aqua !important;
}
</style>
