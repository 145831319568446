<template>
  <div>
    <b-modal
      ref="edit-detail-modal"
      id="edit-detail-modal"
      title="แก้ไขรายละเอียด"
      size="lg"
      hide-footer
    >
      <b-card-text>
        <!--- START (รายละเอียด) --->
        <b-row class="mt-1">
          <b-col md="12" class="">
            <label>รายละเอียด</label>
            <b-form-textarea
              id="textarea-default"
              placeholder="รายละเอียด"
              rows="2"
              size="sm"
              v-model="onhandData.detail"
            />
          </b-col>
        </b-row>
        <!--- END (รายละเอียด) --->

        <!--- START (หมายเหตุ) --->
        <b-row class="mt-1">
          <b-col md="12" class="">
            <label>หมายเหตุ</label>
            <b-form-textarea
              id="textarea-default"
              placeholder="หมายเหตุ"
              rows="2"
              size="sm"
              v-model="onhandData.comment"
            />
          </b-col>
        </b-row>
        <!--- END (หมายเหตุ) --->

        <!--- START (BTN SUBMIT) --->
        <b-row class="mt-1">
          <b-col md="12" class="d-flex justify-content-end">
            <b-button variant="primary" size="sm" @click="save" :disabled="isLoading"
              >
              <span v-if="isLoading">กำลังบันทึก...</span>
              <span v-else>บันทึก</span>
              </b-button
            >
          </b-col>
        </b-row>
        <!--- END (BTN SUBMIT) --->
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  VBModal,
  BCardText,
  BRow,
  BCol,
  BFormInput,
  BFormTextarea,
  BButton
} from "bootstrap-vue";
import vSelect from "vue-select";

//SERVICES
import OnhandService from "@/services/stock/onhand";

export default {
  props: ["onhandData"],
  components: { BCardText, BRow, BCol, BFormInput, BFormTextarea, BButton,  vSelect },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      isLoading:false,
      userLogin:{}
    };
  },
  created(){
    const userLoginData = localStorage.getItem("userData");
    const userData = JSON.parse(userLoginData);
    this.userLogin = userData;
  },
  methods: {
    async save() {
      this.isLoading = true
      let obj = {...this.onhandData,createBy:this.userLogin.userId}
      await OnhandService.UpdateOnhandStockDetail(
        obj,
        this.onhandData.id
      );
      this.isLoading = false
      this.hideModal();
    },
    hideModal() {
      this.$emit("saved")
      this.$refs["edit-detail-modal"].hide();
    },
  },
};
</script>

<style>
@import "./style.css";
</style>
