export default [
  {
    key: "no",
    label: "#",
    thStyle:{color:"white",width: "3% !important", textAlign: "center"}
  },
  {
    key: "serial",
    label: "Serial No.",
    thStyle:{color:"white",width: "10% !important", textAlign: "center"}
  },
  { key: "hwTypeName", label: "Hardware type", sortable: true , thStyle:{color:"white",width: "8% !important", textAlign: "center"}},
  { key: "hwBrandName", label: "Brand", sortable: true ,thStyle:{color:"white",width: "7% !important", textAlign: "center",}},
  { key: "hwModelName", label: "Model", sortable: true ,thStyle:{color:"white",width: "7% !important", textAlign: "center",}},
  { key: "fullname", label: "Onhand", sortable: true ,thStyle:{color:"white",width: "10% !important", textAlign: "center",}},
  { key: "branchName", label: "Owner", sortable: true ,thStyle:{color:"white",width: "10% !important", textAlign: "center",}},
  { key: "ticketNo", label: "Job No.", sortable: true ,thStyle:{color:"white",width: "7% !important", textAlign: "center",}},  
  { key: "detail", label: "Detail", sortable: true , thStyle:{color:"white",width: "10% !important", textAlign: "center"}},
  { key: "comment", label: "Comment", sortable: true , thStyle:{color:"white",width: "10% !important", textAlign: "center"}},
  { key: "onhandDate", label: "Date", sortable: true , thStyle:{color:"white",width: "7% !important", textAlign: "center"}},

  {
    key: "action",
    label: "จัดการ",
    sortable: false,
    thStyle: { width: "11% !important", textAlign: "center", color:"white" },
  },
];
